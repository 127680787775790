<template>
  <div class="content">
    <div class="m-card" style="padding-top:20px;">
      <act></act>
    </div>
  </div>
</template>

<script>
import act from "./components/act/act";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "layout",
  components: { act },
  data() {
    return {
      showTabs: "1",
      actTabs: "1",
      count: {
        apply: 0,
        follow: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user: "getUser",
    }),
  },
  created() {
    this.getCountWarp();
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
    }),
    getCountWarp() {
      this.getCount("apply");
      this.getCount("follow");
    },
    async getCount(type) {
      let query = ``;
      let where = {
        Equal: {
          n: "userId",
          v: this.user.userId,
        },
        EqualA: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
      };
      if (type == "apply") {
        query = `
          query($query:QueryInput!){
            activityRegQuery{
              query(query:$query){
                totalCount
              }
            }
          }
        `;
      } else if (type == "follow") {
        query = `
          query($query:QueryInput!){
            activityFollowQuery{
              query(query:$query){
                totalCount
              }
            }
          }
        `;
      }
      let opt = {
        query: query,
        variables: {
          query: {
            page: 10000,
            size: 1,
            where: JSON.stringify(where),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = {};
      if (type == "apply") {
        res = JSON.parse(JSON.stringify(data.data.activityRegQuery.query));
      } else if (type == "follow") {
        res = JSON.parse(JSON.stringify(data.data.activityFollowQuery.query));
      }
      if (res && res.totalCount) {
        this.count[type] = res.totalCount;
      }
  
    },
    switchBrand(name) {
      if (this.actTabs != this.showTabs) {
        this.actTabs = this.showTabs;
        this.getCountWarp();
      }
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.content {
  .m-card {
    min-height: 700px;
    border: solid 1px #ededed;
    .mc-top-title::before {
      opacity: 0;
      font-size: 16px;
    }
  }
}
</style>
